.child {
  background-color: var(--color-gray-100);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  position: relative;
}

.ru-1-icon {
  position: absolute;
  top: 0;
  left: 26%;
  height: 416px;
  width: 87px;
  scale: 1.015;
}

.ru-02-icon {
  position: absolute;
  left: 40%;
  width: 208px;
  height: 416px;
}
.inner {
  scale: 1.015;
  position: absolute;
  left: 4%;
  top: 40%;
  width: 393.5px;
  height: 57.4px;
}
.div {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #27292b;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.item,
.ru-1-icon,
.inner,
.ru-02-icon {
  opacity: 0; /* Initially hide all images */
  animation-fill-mode: forwards;
}

.item {
  animation: fadeIn 3s 0.5s; /* Start after 0.5s */
}

.ru-1-icon {
  animation: fadeIn 2s 1s; /* Start after 1s */
}

.inner {
  animation: fadeIn 1s 1.5s; /* Start after 1.5s */
}

.ru-02-icon {
  animation: fadeIn 1s 2s; /* Start after 2s */
}

.box {
  width: 200px;
  height: 200px;
  background: #fff;
}
