body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6; /* Add a custom line height if needed */
}

.App {
  text-align: center;
}

/* Optional: Add any additional styles you need below */
